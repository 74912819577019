<script>
import FiltroReservaCartaoMargens from '@/components/shared/relatorio/filtros/FiltroReservaCartaoMargens.vue'

export default {
  extends: FiltroReservaCartaoMargens,

  methods: {
    carregarListaEntidades() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarConsignataria() {
      this.consignatariasSelecionadas.push(
        JSON.parse(localStorage.getItem('consignatariaSelecionada')),
      )
      this.adicionarConsignataria()
      this.exibirListaConsignataria = false
    },
  },
}
</script>
